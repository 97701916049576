import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  // FaFax,
  FaPhone,
  FaMailBulk,
  FaWhatsapp,
} from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import {
  FooterContainer,
  FooterSubscription,
  FooterSubHeading,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  Wrapper,
  Row,
  Column,
  Title,
  Tautan,
  Subscription,
  AppLogo,
  AppIcon,
  SubHeading,
} from './FooterStyles';

const Footer = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <Row>
          <Column>
            <FooterSubscription>
              <SocialLogo to="/">
                <SocialIcon src="./images/logo.png" />
              </SocialLogo>
              <FooterSubHeading>
                Bimbel Terbaik dan Terbesar Se-Indonesia
              </FooterSubHeading>
            </FooterSubscription>
          </Column>
          <Column>
            <Subscription>
              {/* <SubHeading>Download juga GO Kreasi</SubHeading> */}
              <Title>Download GO Expert</Title>
              <AppLogo to="/">
                <AppIcon src="images/google play.png" />
                <AppIcon src="images/appstore.png" />
              </AppLogo>
              <Title>Keamanan Belanja</Title>
              <SocialIcon src="./images/belanja.png" />
            </Subscription>
          </Column>
          <Column>
            <Title>Hubungi Kami</Title>
            <Tautan>
              <FaPhone /> (022)4218177
            </Tautan>
            <Tautan href="https://mail.google.com/mail/u/0/?fs=1&to=officialgopusat@gmail.com&su=Tanya+minGO&tf=cm">
              <FaMailBulk /> officialgopusat@gmail.com
            </Tautan>
            <Tautan href="https://api.whatsapp.com/send?phone=628112468988&text=&source=&data=">
              <FaWhatsapp /> +62 811-2468-988
            </Tautan>
            <Tautan href="https://page.line.me/?accountId=769igxyd">
              <i className="bi bi-line"></i> Ganesha Operation
            </Tautan>
          </Column>
          <Column>
            <Title>Info Lebih Lanjut</Title>
            <Tautan href="https://ganeshaoperation.com/faq.html">
              FAQ
            </Tautan>
            <Tautan href="https://ganeshaoperation.com/kontak.html">
              Kontak
            </Tautan>
            <Tautan href="https://ganeshaoperation.com/term1.html">
              Syarat dan Ketentuan
            </Tautan>
            <Tautan href="https://ganeshaoperation.com/privacy1.html">
              Kebijakan Privasi
            </Tautan>
            <Tautan href="https://ganeshaoperation.com/refund1.html">
              Refund Policy
            </Tautan>
          </Column>
        </Row>
      </Wrapper>
      <SocialMedia>
        <SocialMediaWrap>
          <WebsiteRights>
            © Copyright 2024, Ganesha Operation. All Rights Reserved
          </WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href="https://id-id.facebook.com/ganesha.operation.official/"
              target="blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.instagram.com/officialganeshaoperation/"
              target="blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              href="https://twitter.com/officialgopusat"
              target="blank"
              aria-label="Twitter"
            >
              <BsTwitterX />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.youtube.com/c/GaneshaOperationOfficial/videos"
              target="blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
};

export default Footer;
