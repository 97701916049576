import React from 'react';
import Slider from 'react-slick';

import '../assets/card.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import putri from '../assets/putriani.png';
import pangestu from '../assets/pangestu.png';
import gemi from '../assets/gemi.png';
import nabila from '../assets/testinabila.png';
import amirulrayyis from '../assets/testiamirulrayyis.png';
import mfarouq from '../assets/testiMFarouq.png';
import Card from './Card';
const Testimoni = (props) => {
  const data = [
    <Card
      img={pangestu}
      diterima="FTTM - ITB"
      nama="Pangestu Zahid"
      testi="Seru banget! Pengajarnya asyik sering becanda juga, tapi materinya tetap bisa masuk. Selain itu aku juga suka banget sama fasilitas Buku KODING karena enak banget ada materinya, ada latihan soal per materi, dan soalnya pun mirip banget sama UTBK, pokoknya jadi makin gampang dan bikin paham"
      click={() => {
        props.pangestu();
        props.openmodal();
      }}
    />,
    <Card
      img={nabila}
      diterima="FK - UI"
      nama="Nabila Amalia Wahyuni"
      additional="Peraih Nilai 1.000 UTBK 2023"
      testi="GO sangat membantu saya saat mempersiapkan SNBT karena jika ada materi yang tidak dipahami saya bisa menanyakan kepada pengajar saat TST, juga berkat adanya konsultasi TOBK saya semakin termotivasi dan terdukung untuk bisa lolos ke Jurusan dan PTN impian."
      click={() => {
        props.nabila();
        props.openmodal();
      }}
    />,
    <Card
      img={putri}
      diterima="Farmasi - ITB"
      nama="Putriani Nabila"
      testi="Happy banget belajar di GO, temennya seru dan supportif, pengajarnya juga keren-keren banget karena bikin aku ngerti sama materinya. Fasilitas favorit aku adalah TST, karena TST bantu aku banget dalam memecahkan soal dan juga aku jadi memahami materi terutama pada saat mau ujian."
      click={() => {
        props.putriani();
        props.openmodal();
      }}
    />,
    <Card
      img={amirulrayyis}
      diterima="STEI - ITB"
      nama="Amirul & M. Rayyis"
      testi="GO membantu banget! Apa lagi dengan adanya program Super Intensif menjelang SNBT, pengajar-pengajarnya juga siap membantu kita setiap hari agar bisa memahami materi yang akan keluar di UTBK"
      click={() => {
        props.amirul_rayyis();
        props.openmodal();
      }}
    />,
    <Card
      img={gemi}
      diterima="SAPPK - ITB"
      nama="Gemi Nastiti Deandaru Putri"
      testi="Aku udah di GO sejak kelas 5 SD sampai kelas 12 SMA, soalnya di GO tuh cara belajarnya ambis banget tapi tetap santai, jadi bantu aku lebih rajin dan semangat di sekolah. Soal-soal di GO juga relate banget sama soal di sekolah dan juga sangat membantu aku untuk persiapan ujian dan kuis-kuis di sekolah."
      click={() => {
        props.gemi();
        props.openmodal();
      }}
    />,
    <Card
      img={mfarouq}
      diterima="Teknik Geologi - Unpad"
      additional="Mahasiswa Termuda Unpad 2023"
      nama="M. Farouq Al-Farisi"
      testi="KBM di GO, TST, dan responsi PAT sangat membantu saya pada saat ujian dan saat PAT, sehingga nilai raporku menjadi naik secara signifikan hingga akhirnya aku bisa masuk ke Unpad jurusan Teknik Geologi melalui jaluir SNBP."
      click={() => {
        props.mfarouq();
        props.openmodal();
      }}
    />,
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    className: 'center',
    centerMode: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          centerMode: false,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mb-5">
      <div className="container-carousel" id="testimoni">
        <h1 className="w-full mx-auto mb-4 text-center testimonigo">
          Yuk simak apa kata mereka tentang Ganesha Operation!
        </h1>

        <Slider {...settings} className="card__container--inner">
          {data.map((item, index) => {
            return <div key={index}>{item}</div>;
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Testimoni;
