import React, { useState } from "react";
import { FaBook, FaFire } from "react-icons/fa";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 0.8rem;
  
`;

const DropdownButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #f35b6c;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s;


`;

const DropdownIcon = styled.svg`
  margin-left: 0.5rem;
  height: 1.25rem;
  width: 1.25rem;
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  width: 12rem;
  text-align: left;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background-color: white;
  border: 1px solid #f35b6c;
  z-index: 10;
  background-color: #ec4357;
`;

const DropdownItem = styled.a`
  display: block;
  padding: 0.5rem;
  color: white;
  font-size: 0.875rem;
  text-decoration: none;
  transition: background-color 0.2s;

  &:hover {
color: white;
  }
`;

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  //   const [selectedOption, setSelectedOption] = useState("Event");

  const selectedOption = "Event";

  const options = [
    {
      label: "TOBK",
      link: "https://ganeshaoperation.com/tobk_nasional_go/",
      target: "_blank",
      icon: (
        <FaBook
          style={{
            color: "white",
            marginRight: "0.2rem",
            marginBottom: "0.1rem",
          }}
        />
      ),
    },
    {
      label: "The Champion Race",
      link: "https://tcr.ganeshaoperation.com",
      target: "_blank",
      icon: (
        <FaFire
          style={{
            color: "white",
            marginRight: "0.2rem",
            marginBottom: "0.1rem",
          }}
        />
      ),
    },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  //   const handleOptionClick = (option) => {
  //     setSelectedOption(option.label);
  //     setIsOpen(false);
  //     window.open(option.link, option.target);
  //   };

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        <span style={{ fontSize: "1.1rem" }}>{selectedOption}</span>
        <DropdownIcon
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </DropdownIcon>
      </DropdownButton>

      {isOpen && (
        <DropdownMenu>
          {options
            .filter((option) => option.label !== selectedOption)
            .map((option) => (
              <DropdownItem
                key={option.label}
                href={option.link}
                // onClick={(e) => {
                //   e.preventDefault();
                //   handleOptionClick(option);
                // }}
                target={option.target}
                rel="noopener noreferrer"
              >
                {option.icon}
                {option.label}
              </DropdownItem>
            ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
