import React from 'react'
import Detail3b from './detail3b';
import { homeObjTwo } from './detail3b/Data';

const berlatih = () => {
  return (
    <>
      <Detail3b {...homeObjTwo}/>
    </>
  )
}

export default berlatih