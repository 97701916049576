import styled from 'styled-components';

export const ServicesContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url('https://res.cloudinary.com/dyhbjdod5/image/upload/v1692847268/18786286_SL-072321-44560-17-ai_1_ok8hca.png');
  background-size: cover; */
  background: rgb(255, 179, 0);
  background: linear-gradient(
    74deg,
    rgba(255, 179, 0, 1) 0%,
    rgba(245, 187, 52, 1) 66%,
    rgba(251, 209, 110, 1) 100%
  );
  padding: 28px 4px;
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 443.19px;
  /* max-height: 640px; */
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 1000px) {
    height: 100%;
  }
`;

export const ServicesIcons = styled.img`
  width: 160px;
  margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
  font-family: 'Archivo', sans-serif;
  font-size: 40px;
  font-weight: 700;
  /* color: white; */
  color: #212529;
  margin-bottom: 32px;
  text-align: center;
  margin-top: 16px;

  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`;

export const TigaButton = styled.button`
  width: 250px;
  margin: auto;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 0.688rem;
  line-height: 18px;
  letter-spacing: 1.54px;
  text-transform: uppercase;
  border-radius: 25px;
  border: none;
  background-color: white;
  color: #ec2b39;
  cursor: pointer;
  transition: all 0.4s ease-in;
  text-align: center;
  margin-top: 30px;
`;
