export const homeObjOne = {
  id: 'program',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Fasilitas untuk membantumu belajar',
  headLine: 'BELAJAR',
  description: (
    <p
      style={{
        color: '#010606',
      }}
    >
      Ada banyak fasilitas yang bisa kamu dapatkan saat belajar di GO,
      seperti Buku KODING yang lengkap banget dan pastinya materi yang
      diberikan sesuai dengan kebutuhan SobatGO. Kamu juga bisa
      bertemu dengan Pengajar-pengajar terbaik yang menyenangkan.
      Kalau SobatGO masih kebingungan dan butuh tambahan belajar
      tentang materi pelajaran di sekolah atau diskusi tentang PR dan
      tugas/
      <i>project</i>, kamu bisa minta{' '}
      <i>TST (Tutotial Service Time)</i> di luar jam KBM, Pengajar GO
      bakalan siap melayani kamu! Selain itu, di GO enggak cuma
      belajar tatap muka aja kok, kamu juga bisa belajar secara{' '}
      <i>online</i> menggunakan aplikasi GO Expert yang bisa kamu
      akses di mana saja dan kapan saja.
    </p>
  ),
  buttonLabel: 'Daftar Sekarang',
  imgStart: true,
  img: require('../../../icons/belajar.png'),
  fasilitas: require('../../../icons/tigab/iconbelajar.png'),
  alt: 'SMA',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjTwo = {
  id: 'program',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Fasilitas untuk membantumu berlatih',
  headLine: 'BERLATIH',
  description: (
    <p>
      Berlatih soal jadi lebih asyik karena kamu bisa mengerjakan
      puluhan ribu soal mulai dari yang tingkat kesulitannya rendah
      sampai dengan yang tinggi.
      <i> So</i>, SobatGO enggak perlu khawatir lagi ketika akan
      menghadapi ujian, karena kamu sudah terbiasa mengerjakan
      berbagai variasi tipe soal. SobatGO juga akan mendapatkan Buku
      Sakti yang berisi soal pelajaran sekolah, soal referensi yang
      mirip banget dengan soal ujian sekolah dan UTBK-SNBT. Di sini
      juga kamu akan diajak untuk berlatih dengan fitur EMPATI yang
      pastinya bikin kamu makin semangat kejar target pengerjaan soal.
      Pokoknya SobatGO enggak perlu khawatir, di sini kamu bisa
      berlatih mengerjakan soal dengan menyenangkan!
    </p>
  ),
  buttonLabel: 'Daftar Sekarang',
  imgStart: false,
  img: require('../../../icons/berlatih.png'),
  fasilitas: require('../../../icons/tigab/iconberlatih.png'),
  alt: 'SMA',
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjThree = {
  id: 'program',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Fasilitas untuk membantumu bertanding',
  headLine: 'BERTANDING',
  description: (
    <p>
      GO akan mempersiapkan simulasi ujian kamu melalui TOBK sebelum
      kamu mengikuti ujian yang sebenarnya. Soal-soal yang disiapkan
      untuk TOBK adalah soal-soal yang relevan dengan materi ujian
      kamu di sekolah atau di UTBK-SNBT. Dengan mengikuti TOBK kamu
      bisa tahu keunggulan dan kelemahanmu dalam menguasai materi yang
      diujikan karena hasil TOBK GO dianalisis hingga per butir soal,
      jadi nanti kamu akan menerima laporan EPB (Evaluasi Prestasi
      Belajar), wih keren, kan? <br />
      Selain TOBK, kamu juga bisa bertanding mengerjakan soal dengan
      mutualan SobatGO lainnya menggunakan fitur <i>
        Battle
      </i> dan{' '}
      <i
      // style={{
      //   color: '#010606',
      //   opacity: '0.95',
      //   fontWeight: 'lighter',
      //   fontStyle: 'italic',
      // }}
      >
        {' '}
        Racing
      </i>{' '}
      di GO Expert. Di situ kalian akan beradu kemampuan mengerjakan
      soal, sehingga kemampuan SobatGO akan semakin terasah dan pada
      akhirnya bisa meraih kemenangan pada ujian yang sesungguhnya.
    </p>
  ),
  buttonLabel: 'Daftar Sekarang',
  imgStart: true,
  img: require('../../../icons/bertanding.png'),
  fasilitas: require('../../../icons/tigab/iconbertanding.png'),
  alt: 'SMA',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjFour = {
  id: 'program',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Program Gap Year',
  headLine: 'Sukses SNBT di PTN Impianmu',
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
  buttonLabel: 'Daftar Sekarang',
  imgStart: true,
  img: require('../../../icons/gambar5.svg'),
  alt: 'SMA',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjFive = {
  id: 'whyus',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Kenapa Harus Pilih Ganesha Operation?',
  headLine: 'GO Telah Meluluskan 43.000 Lebih Siswa ke PTN',
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
  buttonLabel: 'Lihat Selengkapnya',
  imgStart: true,
  img: require('../../../icons/gambar4.svg'),
  alt: 'SMA',
  dark: false,
  primary: false,
  darkText: true,
};
