import React from 'react';

import '../assets/card.css';

export default function Card(props) {
  return (
    <div className="card-test-container position-relative">
      <div
        onClick={props.click}
        className=" card-testi-yt"
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <div className="card-test-diterima">
          {/* <img src={itb} /> */}
          <p className='fw-bold'>{props.nama}</p>
          <p>{props.additional}</p>
          <p> Diterima di</p>

          <p className="fw-bold"> {props.diterima}</p>
        </div>

        <div className=" card-testi-texttestimoni ">
          <small className="text-center py-2 px-1">
            "{props.testi}"
          </small>
        </div>
      </div>
    </div>
  );
}
