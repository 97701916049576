import styled from "styled-components";
import { Container } from "../../globalStyles";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  margin-bottom: -80px;
  /* margin-top: 10px; */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: start;
  /* align-items: center; */
  height: 80px;
  /* width: 1000px; */

  /* @media screen and (min-width: 960px) {
    border-bottom: 1px solid white;
  } */
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  z-index: 99;
`;

export const NavIcon = styled.img`
  margin-right: 0.2rem;
  width: 7rem;
`;

export const MobileIcon = styled.div`
  display: none;
  z-index: 99;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1192px) {
    font-size: 0.9em;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding: 16px;
    height: 100vh;
    position: fixed;
    padding-top: 72px;
    align-items: center;
    top: 0;
    left: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
    visibility: ${({ show }) => (show ? "visible" : "hidden")};
    transform: translateY(${({ show }) => (show ? "0" : "-10px")});
    transition: opacity 0.5s ease;
    background-color: #ec323f;
  }

  > li:first-child {
    margin-left: auto;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 60px;

    &:hover {
      border: none;
    }
  }
`;
export const NavItemButton = styled.button`
  height: 40px;
  width: 80px;
  cursor: pointer;
  background-color: rgb(255, 193, 10);
  border: 0.5px solid orange;
  border-radius: 12px;
  &:hover {
    border: none;
    background-color: #f9c452;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 2em;

    &:hover {
      border: none;
    }
  }
`;

export const NavLinks = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.6rem;
  height: 100%;

  &:hover {
    border-bottom: 2px solid #fff;
    color: #ffc10a;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      border-bottom: none;
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;

export const Navigate = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  /* height: 100%; */

  &:hover {
    border-bottom: 2px solid #fff;
    color: #ffc10a;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    /* width: 100%; */
    display: table;

    &:hover {
      border-bottom: none;
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;
