import React from 'react';
import './Header3b.css';


const Header3b = () => {
  return (
    <>
    
      <div className="hero3b">
        <div className="containerheader3b ">
          {/* Left Side */}
          <div className="left">
            <div className="img-header3b">
              <img src="images/target.png" alt="faq" />
            </div>
          </div>

          {/* Right Side */}
          <div className="right text-light">
            <div>
              {' '}
              <h1>
                Inilah cara GO bantu kamu mencapai target akademikmu!
              </h1>
            </div>
            <p>
              Enggak cuman asal belajar, sebagai{' '}
              <i>training center</i> terbaik dan terbesar se-Indonesia
              GO punya formula 3B yang bisa bantu kamu agar berhasil
              mencapai target akademik. Ketiga formula itu adalah
              Belajar, Berlatih, dan Bertanding.{' '}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header3b;
