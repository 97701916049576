import React from 'react';
import './Diskon.css';
import Diskonitem from './Diskonitem';

import { CgArrowLongRight } from 'react-icons/cg';
// import Countdowntimer from '../CountdownTimer/Index';

const style = {
  color: '#F5BB34',
  fontSize: '1.5em',
  position: 'relative',
  top: '6px',
};

function Diskon() {
  // const Timeragt = Countdowntimer(
  //   '2023-08-19T23:59:00',
  //   '2023-08-17T00:01:00'
  // );
  // const Show = Timeragt.condition;

  return (
    <div className="cards" id="diskon">
      <h1 className="mt-4">Cek diskon terbaru kami!</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          {/* {Show && (
            <div
              className=" mx-auto mb-4 d-none d-sm-block "
              style={{
                padding: '1rem',
              }}
            >
              <div className="  banner-diskonagt d-flex mx-auto  position-relative ">
                <div className=" banner-diskonagt-left text-center">
                  <p>SPESIAL BUAT KAMU</p>
                  <p>DISKON TAMBAHAN</p>
                  <h1>10%</h1>
                </div>

                <div className="banner-diskonagt-right  mt-4 text-light d-flex  flex-fill">
                  <div className="">
                    <p className="banner-diskon-agt-masukan">
                      Masukan kode voucer
                    </p>
                    <p className="banner-diskonagt-goagt3 ">GOAGT3</p>
                    <p className="fs-6">
                      BERLAKU SAMPAI DENGAN 19 AGUSTUS 2023
                    </p>
                  </div>
                  <img
                    alt="go"
                    src="/images/Diskon/peoplebanneragt.png"
                    className="position-absolute bottom-0 end-0 me-4 d-none d-xl-block "
                    style={{
                      width: '20%',
                      maxWidth: '190px',
                    }}
                  />
                </div>
              </div>
            </div>
          )} */}

          <div className="cards__items ">
            {/* {Show && (
              <img
                alt="go"
                style={{
                  width: '90%',
                }}
                className="d-block d-sm-none mx-auto mb-4"
                src="/images/Diskon/mobilebannerdiskonagt.png"
              />
            )} */}
            <Diskonitem
              src="images/Diskon/diskon3.png"
              text="Diskon ini berlaku sepanjang Tahun Pelajaran."
              label="Diskon"
              path="/diskonlengkap"
            />
            <Diskonitem
              src="images/Diskon/diskon5.png"
              text="Diskon ini berlaku sepanjang Tahun Pelajaran."
              label="Diskon"
              path="/diskonlengkap"
            />
            <Diskonitem
              src="images/Diskon/diskon4.png"
              text="Diskon ini berlaku sepanjang Tahun Pelajaran."
              label="Diskon"
              path="/diskonlengkap"
            />
          </div>
          <div className="Tautan">
            <a href="https://ganeshaoperation.com/diskon1.html">
              Lihat Semua Diskon <CgArrowLongRight style={style} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Diskon;
