import React from 'react'
import Detail3b from './detail3b';
import {homeObjThree} from './detail3b/Data';

const bertanding = () => {
  return (
    <>
      <Detail3b {...homeObjThree}/>
    </>
  )
}

export default bertanding