import React from "react";
import "./index.css";

const Modaltesti = (props) => {
    return (
        <div>
            <div className="modaltestiyt-bg"></div>
            <div className="modaltestiyt-container">
                <div className="modaltestiyt-wrapper position-relative">
                    <div className="embed-responsive embed-responsive-21by9">
                        <iframe
                            className="iframe-testi"
                            src={props.src}
                            title="Testimoni GO"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    </div>
                    <div
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={props.close}
                        className="position-absolute top-0 start-100 translate-middle"
                    >
                        <h1
                            className="text-white fw-bold"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="Close"
                        >
                            x
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modaltesti;
