import React from 'react';
import Data from './Data';
import '../../App.css';
import './index.css';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  gridAnimation,
  cardAnimation,
  h3Animation,
} from './Animations';
import Tilt from 'react-tilt';

const Cars = () => {
  return (
    <div className="carsContainer">
      <div
        style={{
          marginBottom: '40px',
        }}
      >
        <motion.h3 variants={h3Animation} animate="show" exit="hide">
          Program Pilihan Ganesha Operation
        </motion.h3>
        <motion.p variants={h3Animation} animate="show" exit="hide">
          Berbagai Macam Pilihan Program Sesuai dengan Kebutuhanmu
        </motion.p>
      </div>
      {/* <motion.div
        className="cars"
        variants={gridAnimation}
        animate="show"
        exit="hide"
      > */}
      <div className="container-programsgo">
        <div className="wrapper-programsgo">
          {' '}
          {Data.map((item) => {
            return (
              <Link
                style={{
                  textDecoration: 'none',
                }}
                to={`/car/${item.id}`}
                key={item.id}
              >
                <motion.div variants={cardAnimation}>
                  <Tilt
                    className="Tilt"
                    options={{ max: 16, scale: 1.01, speed: 200 }}
                  >
                    {/* <img
                    whilehover={{ scale: 1.1 }}
                    transition={{ duration: 0.5 }}
                    src={`../images/products/${item.image}`}
                    alt={item.car}
                  /> */}

                    <div
                      style={{
                        textDecoration: 'none',
                      }}
                      class="cardprogramgo position-relative"
                    >
                      <div class="substituteprogramgo">
                        <img
                          src={`../images/products/${item.newimage}`}
                          style={{
                            width: '100%',
                            borderRadius: '5%',
                          }}
                          alt="Program Ganesha operation"
                        />
                      </div>
                      <p>
                        <span class="bold-text text-danger">
                          {item.car}
                        </span>
                      </p>
                      <p
                        style={{
                          marginTop: '-12px',
                        }}
                        className="description-programsgo"
                      >
                        {item.thubmnaildesc}
                      </p>

                      <b className="d-none d-sm-block sm-block mb-4 text-danger position-absolute bottom-0 start-50 translate-middle-x">
                        Lihat Selengkapnya
                      </b>
                      <b className="d-block d-sm-none text-danger">
                        Lihat Selengkapnya
                      </b>
                    </div>
                  </Tilt>

                  {/* <div className="container-programsgo">
                  <div className="img-programsgo">IMAGE</div>
                  <div className="description-programsgo">
                    Description{' '}
                  </div>
                </div> */}
                </motion.div>
              </Link>
            );
          })}
        </div>

        {/* </motion.div> */}
      </div>
    </div>
  );
};

export default Cars;
