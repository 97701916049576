import axios from "axios";

// Define the new API endpoint
// const apiEndpoint = 'https://support-api.ganeshaoperation.com/api/blog/content';
const apiEndpoint = process.env.REACT_APP_BLOG_ENDPOINT;
// const apiKey = 'KKbUHHREOLc8f5NFJfxkB4Xbu3a5U2hW';
const apiKey = process.env.REACT_APP_BLOG_API_KEY;

export const getPost = async (page, limit, excerpt) => {
  try {
    // Set up headers
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    };

    // Set up query parameters
    const params = {
      page: page,
      limit: limit,
      excerpt: excerpt,
    };

    // Send a GET request to the new API endpoint using axios with headers and query parameters
    const { data } = await axios.get(apiEndpoint, {
      params: params,
      headers: headers,
    });

    // Process the data and return the desired result
    return data;
  } catch (error) {
    console.error("Error fetching posts:", error);
    throw error; // Rethrow the error to handle it further up the call stack
  }
};
