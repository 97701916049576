import React from "react";
import "./Gokre.css";

const Gokre = () => {
  return (
    <div className="herogokre">
      <div className="containergokre">
        {/* Left Side */}
        <div className="gokreleft">
          <div className="img-gokre">
            <img
              src="images/gokre.png"
              alt="go kreasi"
              style={{
                maxWidth: "285px",
              }}
              // height={'470px'}
            />
          </div>
        </div>

        {/* Right Side */}
        <div className="gokreright">
          <h2 className="w-75">
            Belajar, Berlatih, dan Bertanding kapan saja dan di mana saja dengan
            GO Expert!
          </h2>
          <p>Download Aplikasi GO Expert Sekarang</p>
          <div className="img-download">
            <a href="https://play.google.com/store/apps/details?id=com.gobimbel_online&pcampaignid=web_share">
              <img src="images/google play.png" alt="googleplay" />
            </a>
            <a href="https://apps.apple.com/id/app/gokreasi/id1478372788">
              <img src="images/appstore.png" alt="appstore" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gokre;
