import React, { useState, useEffect, useRef } from 'react';

import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
  FaLine,
} from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import {
  ProfileContainer,
  ProfileWrap,
  SocialIcons,
  SocialLogo,
  ProfileDetails,
  SocialMedia,
  SocialMediaWrap,
  SocialIconLink,
  Subtitle,
} from './ProfileElements';
import { useAnimation, motion } from "framer-motion";
// import Typical from 'react-typical';

const Profile = () => {
  const [data, setData] = useState(
    'Bimbingan Belajar Terbaik dan Terbesar Se-Indonesia'
  );
  let count = useRef(0);
  // const styleh4 = {
  //   color: 'black',
  // };

const controls = useAnimation();

useEffect(() => {
  const arr = [
    "Bimbingan Belajar Terbaik dan Terbesar Se-Indonesia",
    "Pada Tahun 2024 Ganesha Operation Telah Meluluskan 50.000 Lebih Siswa ke PTN & PT Kedinasan Impian",
    "Menerapkan Formula 3B, Yaitu: Belajar, Berlatih, Bertanding",
    "Memiliki Konsep The King, Berpikir Kreatif Sebagai Strategi Untuk Menjawab Soal dengan Mudah, Cepat, dan Akurat",
    <p>
      Fasilitas <i>Tutorial Service Time</i> (TST) Untuk Belajar Tambahan di
      Luar KBM
    </p>,
    "Pengajar Ramah dan Akrab",
  ];

  function cycleArray() {
    controls
      .start({
        opacity: 0,
        transition: { duration: 0.5 },
      })
      .then(() => {
        setData(arr[count.current]);
        count.current++;
        if (count.current === arr.length) {
          count.current = 0;
        }
        controls.start({
          opacity: 1,
          transition: { duration: 0.5 },
        });
      });
  }

  const intervalId = setInterval(cycleArray, 5000);
  return () => clearInterval(intervalId);
}, [controls]);

  return (
    <>
      <ProfileContainer id="about">
        <ProfileWrap>
          <SocialLogo to="/">About Us</SocialLogo>
          <ProfileDetails>
            {" "}
            {/* <Typical
                loop={Infinity}
                steps={[
                  'Bimbingan Belajar Terbaik dan Terbesar Se-Indonesia',
                  4000,
                  'Telah Meluluskan 45.000 Lebih Siswa ke PTN & Kedinasan Impian di Tahun 2022',
                  4000,
                  'Menerapkan Konsep 3B, Yaitu : Belajar, Berlatih, Bertanding',
                  4000,
                  'Memiliki Konsep The King, Berpikir Kreatif Sebagai Strategi Untuk Menjawab Soal dengan Mudah, Cepat, dan Akurat',
                  4000,
                  'Fasilitas Tutorial Service Time (TST) Untuk Belajar Tambahan di Luar KBM',
                  4000,
                  'Pengajar Ramah dan Akrab',
                  4000,
                ]}
              /> */}
            <motion.div
              animate={controls}
              initial={{ opacity: 1 }}
              className="text-center"
            >
              {data}
            </motion.div>
          </ProfileDetails>
          <Subtitle>
            <div>
              Ganesha Operation adalah bimbingan belajar tatap muka berbasis
              teknologi <i>online</i> yang telah berhasil mengantarkan 50.000
              lebih kelulusan siswa ke PTN dan PT Kedinasan, 2.500 lebih di
              antaranya diterima di kedokteran pada tahun 2024 dan angka
              tersebut terus meningkat setiap tahunnya. Kami telah meraih 4
              penghargaan MURI yaitu sebagai:
            </div>
            <br />
            <>
              <ul style={{ listStyle: "decimal", marginLeft: "-15px" }}>
                <li>
                  Bimbel Terbaik dengan Kelulusan Siswa Terbanyak ke PTN dan PT
                  Kedinasan se-Indonesia.
                </li>
                <li>
                  Bimbel Terbesar dengan Lokasi Terbanyak se-Indonesia yang
                  Dikelola Secara Terpusat
                  <i> (no franchise) </i>.
                </li>
                <li>
                  Bimbel Pertama yang Menyelenggarakan <i>E-Sport</i>{" "}
                  Pendidikan.
                </li>
                <li>Bimbel Terlama yang Masih Beroperasi sampai Saat Ini.</li>
              </ul>
            </>
            <br />
            <div>
              Ganesha Operation berdiri sejak 2 Mei 1984, dan memiliki visi
              untuk menjadi bimbingan belajar terbaik dan terbesar se-Indonesia.
            </div>
          </Subtitle>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialIcons>
                <SocialIconLink
                  href="https://id-id.facebook.com/ganesha.operation.official/"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/officialganeshaoperation/"
                  target="_blank"
                  aria-label="Instagram"
                >
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href="https://twitter.com/officialgopusat"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <BsTwitterX />
                </SocialIconLink>
                <SocialIconLink
                  href="https://api.whatsapp.com/send?phone=628112468988&text=&source=&data="
                  target="_blank"
                  aria-label="Whatsapp"
                >
                  <FaWhatsapp />
                </SocialIconLink>
                <SocialIconLink
                  href="https://page.line.me/?accountId=769igxyd"
                  target="_blank"
                  aria-label="Line"
                >
                  <FaLine />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.youtube.com/c/GaneshaOperationOfficial/videos"
                  target="_blank"
                  aria-label="Youtube"
                >
                  <FaYoutube />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </ProfileWrap>
      </ProfileContainer>
    </>
  );
};

export default Profile;
