const Data = [
  {
    id: 1,
    car: "Program SD",
    image: "programsd.png",
    newimage: "newprogramsd.png",
    image2: "newsd.png",
    description: (
      <>
        SobatGO yang masih duduk di sekolah dasar pasti mau kan jadi juara di
        sekolah dan masuk ke SMP favorit? Ganesha Operation bisa banget bantu
        kamu agar nilai rapormu meningkat, sehingga kamu bisa jadi juara di
        sekolah dan masuk SMP favorit. Kamu akan BELAJAR dengan pengajar yang
        akrab dan berkualitas, serta akan dibiasakan untuk BERLATIH mengerjakan
        berbagai variasi soal yang mirip dengan ujianmu di sekolah. Kamu juga
        akan BERTANDING dengan mengikuti <i>Try Out</i> melalui GO Expert yang
        bisa kamu akses di mana saja. Tunggu apa lagi? Yuk, gabung bersama
        Ganesha Operation! Juara sekolah dan SMP favorit sudah menunggumu!
      </>
    ),
    thubmnaildesc:
      "Program ini dibuat khusus untuk SobatGO yang masih duduk di sekolah dasar agar bisa jadi juara di sekolah dan masuk ke SMP favorit.",
    kelas: ["3 SD", "4 SD", "5 SD", "6 SD"],
  },
  {
    id: 2,
    car: "Program SMP",
    image: "programsmp.png",
    image2: "newsmp.png",
    newimage: "newprogramsmp.png",
    description: (
      <>
        SobatGO yang masih duduk di bangku SMP pasti mau kan jadi juara di
        sekolah dan masuk ke SMA favorit? Ganesha Operation bisa banget bantu
        kamu agar nilai rapormu meningkat, sehingga kamu bisa jadi juara di
        sekolah dan masuk SMA favorit. Di sini kamu akan BELAJAR bareng pengajar
        yang akrab dan berkualitas, serta ada banyak variasi soal yang mirip
        dengan ujian di sekolahmu sebagai bahan SobatGO untuk BERLATIH. Melalui
        GO Expert SobatGO akan BERTANDING dengan mengikuti <i>Try Out</i>
        yang bisa diakses di mana saja. Pokoknya lengkap banget! Gabung
        sekarang, yuk!
      </>
    ),
    thubmnaildesc:
      "Program ini dibuat khusus untuk SobatGO yang masih duduk di bangku SMP agar bisa jadi juara di sekolah dan masuk ke SMA favorit.",
    kelas: ["7 SMP", "8 SMP", "9 SMP"],
  },
  {
    id: 3,
    car: "Program SMA",
    image: "programsma.png",
    image2: "newsma.png",
    newimage: "newprogramsma.png",
    description: (
      <>
        Untuk bisa mencapai target akademikmu BELAJAR aja enggak cukup! Kamu
        harus imbangi dengan sering BERLATIH dan BERTANDING. Di Ganesha
        Operation kamu akan BELAJAR dengan pengajar yang akrab dan berkualitas.
        Kamu juga akan dibiasakan untuk BERLATIH mengerjakan berbagai variasi
        soal yang mirip dengan ujianmu di sekolah dan juga UTBK-SNBT. Kamu juga
        akan BERTANDING dengan mengikuti <i>Try Out</i> Berbasis Komputer (TOBK)
        melalui GO Expert dengan soal-soal yang mirip sama ujianmu di sekolah
        dan UTBK-SNBT! Tunggu apa lagi? Yuk, gabung bersama Ganesha Operation!
        Juara sekolah dan PTN impian sudah menunggumu!
      </>
    ),
    thubmnaildesc:
      "Untuk bisa mencapai target akademikmu BELAJAR aja enggak cukup! Kamu harus imbangi dengan sering BERLATIH dan BERTANDING. Ganesha Operation bisa bantu kamu untuk jadi juara di sekolah dan masuk PTN impian!",
    kelas: ["10 SMA MIA/IIS", "11 SMA IPA/IPS", "12 SMA IPA/IPS"],
  },
  {
    id: 4,
    car: "Program Gap Year",
    image: "gapyear.png",
    image2: "newgapyear.png",
    newimage: "newprogramgapyear.png",
    description: (
      <>
        Program gap year adalah program yang dikhususkan untuk kamu yang sedang
        mengambil gap year dan sedang dalam persiapan untuk masuk ke PTN
        impianmu. Di Ganesha Operation, kamu akan belajar dengan fasilitas yang
        lengkap dan juga dibimbing oleh pengajar-pengajar terbaik yang akan
        membantu kamu untuk lulus di PTN impian. GO akan membantu kamu memilih
        program studi berdasarkan nilai prediksi lulus untuk suatu program studi
        menggunakan software simulasi GO. Jadi, tunggu apalagi? Yuk, daftar
        sekarang dan gapai PTN impianmu!
      </>
    ),
    thubmnaildesc: (
      <>
        Kamu tertunda untuk masuk PTN di tahun ini dan masih ingin masuk PTN
        tahun depan? Program <i>Gap Year</i> ini pas banget buat kamu yang ingin
        lulus ke PTN di UTBK-SNBT 2024!'
      </>
    ),
    kelas: ["ALUMNI IPA/IPS"],
  },
  {
    id: 5,
    car: (
      <>
        Bimbel <i>Online</i> Ganesha Operation
      </>
    ),
    image: "bimbelonline.png",
    image2: "newgokre.png",
    newimage: "newprogramgokre.png",
    description: (
      <>
        Untuk kamu yang mager ke tempat bimbel tapi tetap ingin belajar, Ganesha
        Operation menyiapkan GO Expert yang bisa menjadi aplikasi pendamping
        Belajar SobatGO. Konsep dari aplikasi GO Expert sebagai{" "}
        <i>learning center</i> adalah 3B, yaitu BELAJAR, BERLATIH, dan
        BERTANDING. Kamu bisa BELAJAR melalui buku teori dan video pembelajaran,
        lalu BERLATIH dengan puluhan ribu soal yang ada di Buku Sakti, dan
        BERTANDING pada TOBK. Ada juga fitur <i>Battle</i> dan <i>Racing</i>{" "}
        yang bisa kamu lakukan bareng teman-teman mutualanmu di aplikasi GO
        Expert sebagai uji kemampuan kamu dalam mengerjakan soal. Seru bangeet
        kaaan?
      </>
    ),
    thubmnaildesc:
      "Sekarang belajar bisa di mana aja dan kapan aja lho. Video pembelajaran yang menarik dan puluhan ribu soal latihan bisa kamu dapatkan hanya dalam genggaman. ",
    kelas: [
      "3 SD",
      "4 SD",
      "5 SD",
      "6 SD",
      "7 SMP",
      "8 SMP",
      "9 SMP",
      "10 SMA MIA/IIS",
      "11 SMA IPA/IPS",
      "12 SMA IPA/IPS",
      "ALUMNI IPA/IPS",
    ],
  },
  {
    id: 6,
    car: "Program SMK",
    image: "programsmk.png",
    image2: "newSMK.png",
    newimage: "newprogramsmk.png",
    description: (
      <>
        Kamu siswa SMK dan mau melanjutkan pendidikan ke PTN? Ganesha Operation
        siap jadi partner belajar kamu! Di GO, SobatGO akan BELAJAR dengan
        pengajar GO yang akrab dan berkualitas. Kamu juga akan BERLATIH
        mengerjakan puluhan ribu soal yang pastinya relevan dengan UTBK-SNBT.{" "}
        <i>Try Out</i> Berbasis Komputer (TOBK) yang bisa kamu ikuti setiap
        bulan sebagai saranamu BERTANDING dengan SobatGO lainnya se-Indonesia,
        dengan menggunakan sistem
        <i> blocking time</i> dan penilaian
        <i> penilaian sistem Item Response Theory (IRT) </i> membuat TOBK ini
        mirip banget dengan UTBK-SNBT sesungguhnya. Kereen kan? tunggu apa lagi?
        Yuk, gabung GO sekarang juga!
      </>
    ),
    thubmnaildesc: (
      <>
        Kamu siswa SMK dan mau melanjutkan pendidikan ke PTN? Ganesha Operation
        siap jadi
        <i> partner</i> belajar kamu!
      </>
    ),
    kelas: ["10 SMK", "11 SMK", "12 SMK"],
  },
  {
    id: 7,
    car: "Program Ujian Mandiri",
    image: "programUjiman.png",
    image2: "programUjiman.png",
    newimage: "programUjiman.png",
    description: (
      <>
        Program ini cocok banget untuk kamu yang lagi persiapan masuk PTN
        melalui jalur mandiri. Kamu diberikan bahan ajar relevan dan sesuai
        dengan ujian mandiri PTN yang akan kamu ikuti. Belajar menggunakan
        konsep The King bersama pengajar terbaik GO yang berkualitas dan akrab.
        Kamu juga berlatih dengan ribuan soal-soal di aplikasi GO Expert yang
        tentunya relevan dengan ujian mandiri PTN yang akan kamu ikuti. Selain
        itu, kamu akan Bertanding melalui Try Out Berbasis Komputer (TOBK)
        dengan hasil berupa analisisi Evaluasi Prestasi Belajar (EPB) per butir
        soal. Selain itu, kamu juga akan mengetahui peluang lulus di PTN yang
        kamu inginkan. tunggu apa lagi? Yuk, daftar di GO sekarang dan raih
        mimpi untuk bisa lolos ke PTN favoritmu!
      </>
    ),
    thubmnaildesc: (
      <>
        Kamu lagi persiapan masuk PTN Favorit melalui Jalur Mandiri? Program
        Ujian Mandiri ini bisa banget bantu kamu, lho! Program yang dibuat
        khusus karena disesuaikan dengan PTN pilihanmu.
      </>
    ),
    kelas: ["Bimbel Tatap Muka", "Bimbel Online"],
  },
];
export default Data;
