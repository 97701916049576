import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContentSec = styled.div`
  padding: 100px 0;
  background: #fff;
  @media screen and (max-width: 768px) {
    padding: 70px 0;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ reverse }) =>
    reverse ? 'row-reverse' : 'row'};
  justify-content: space-around;

  @media screen and (max-width: 975px) {
    flex-direction: column-reverse;
  }
`;

export const ContentColumn = styled(motion.div)`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  z-index: 10;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 940px;
  padding-top: 0;
  padding: 0px 12px;
  align-content: center;
  padding-bottom: 65px;
  text-align: center;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
    > h1,
    p {
      text-align: center;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > img {
    width: 300px;
    margin-left: -3px;
  }
`;

export const ContentButton = styled(motion.button)`
  height: 3rem;
  padding: 16px 32px;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 18px;
  letter-spacing: 1.54px;
  text-transform: uppercase;
  border-radius: 25px;
  border: none;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#fafeff'};
  cursor: pointer;
  color: white;

  &:hover {
    box-shadow: 0 0 32px 4px #cfcfcf;
    transition: box-shadow 0.3s ease-in;
  }
`;

export const ImgWrapper = styled(motion.div)`
  display: flex;
  justify-content: ${({ imgStart }) =>
    imgStart ? 'flex-start' : 'flex-end'};
  /* max-height: 600px; */
  height: 830px;
  justify-content: center;
  box-shadow: 0 0 32px 4px #dfdfdf;
  border-radius: 1rem;
  position: relative;
  background-image: linear-gradient(
    335deg,
    hsl(42deg 91% 58%) 0%,
    hsl(40deg 95% 67%) 3%,
    hsl(39deg 99% 74%) 6%,
    hsl(39deg 100% 81%) 8%,
    hsl(38deg 100% 87%) 13%,
    hsl(38deg 100% 94%) 24%,
    hsl(0deg 0% 100%) 72%
  );
  &:before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-size: 100% auto;
    object-fit: cover;
  }
  padding: 20px 0px 0px 12px;
  /* border: 1px solid rgba(246, 189, 57, 0.2); */
  /* height: 600px; */
  @media screen and (max-width: 976px) {
    /* height :700px ; */
    height: auto;
  }
  @media screen and (max-width: 575px) {
    height: 720px;
  }
  @media screen and (max-width: 413px) {
    /* height: 1000px; */
  }


`;
export const ImgWrapper2 = styled(motion.div)`
  display: flex;
  justify-content: ${({ imgStart }) =>
    imgStart ? 'flex-start' : 'flex-end'};
  height: 830px;
  justify-content: center;
  box-shadow: 0 0 32px 4px #dfdfdf;
  border-radius: 1rem;
  position: relative;
  background-image: linear-gradient(
    320deg,
    hsl(356deg 83% 56%) 0%,
    hsl(1deg 89% 66%) 3%,
    hsl(4deg 97% 74%) 6%,
    hsl(5deg 100% 80%) 8%,
    hsl(6deg 100% 87%) 12%,
    hsl(7deg 100% 93%) 19%,
    hsl(0deg 0% 100%) 61%
  );
  &:before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-size: 100% auto;
    object-fit: cover;
  }
  padding: 20px 0px 0px 12px;
  @media screen and (max-width: 976px) {
    /* height :700px ; */
    height: auto;
  }
  @media screen and (max-width: 575px) {
    height: 500px;
  }

 
`;

export const TopLine = styled(motion.div)`
  font-weight: 700;

  margin-bottom: 1.3rem;
  color: #1c2237;
  font-family: 'Archivo', sans-serif;
  font-size: 40px;
  font-weight: 700;
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`;

export const Img = styled(motion.img)`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  object-fit: cover;
  height: 500px;
  margin-top: 50px;
  max-height: 700px;
  z-index: 1;

  @media screen and (max-width: 520px) {
    width: 80%;
    height: 80%;
  }
`;

export const Heading = styled(motion.h2)`
  margin-bottom: 24px;
  font-size: 28px;
  line-height: 1.1;
  font-weight: 600;
  color: #1c2237;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
`;

export const Subtitle = styled(motion.p)`
  max-width: 940px;
  margin-bottom: 4px;
  line-height: 24px;
  color: ${({ inverse }) => (inverse ? '#a9b3c1' : '#1c2237')};
`;
