export const heroOne = {
  id: 'projects',
  topLine: {
    text: (
      <>
        Bingung Pilih Bimbel <i>Online</i> atau Tatap Muka?
      </>
    ),
  },
  headline: 'GO Punya Keduanya',
  description: (
    <>
      Kamu ingin belajar di mana saja dan kapan saja? Atau lebih suka
      interaksi langsung dengan pengajar secara tatap muka? Tenang, di
      Ganesha Operation semua kebutuhanmu bisa terpenuhi karena kami
      menggabungkan konsep antara pembelajaran <i>online</i> dengan
      pembelajaran tatap muka.
    </>
  ),
  buttonLabel: 'View Project',
  imgStart: 'start',
  img: './images/content/online.png',
  img2: './images/content/offline.png',
  backgroundColor: '#EC323F',
  backgroundColor2: '#F5BB34',
  start: 'true',
};

export const heroTwo = {
  reverse: true,
  topLine: {
    text: 'Development and Design',
  },
  headline: 'Beautiful Landing Page for Lifecycle',
  description:
    "Our team worked for various clients, I don't know what else to write here, it's not my job!",
  buttonLabel: 'View Project',

  linkTo: '/more',
  imgStart: 'start',
  img: './images/content/landing-page.svg',
  img2: './images/content/rocket.svg',
  backgroundColor: 'linear-gradient(	140deg	,#ffaf73 30%,#fffecc 120%)',

  start: 'true',
};

export const heroThree = {
  topLine: {
    text: 'Development',
  },
  headline: "Innovative API's ",
  description:
    'Our team has amazing developers that can turn any design sketches and ideas into a fully functioning product.',
  buttonLabel: 'View Project',

  linkTo: '/download',
  imgStart: '',
  backgroundColor:
    'linear-gradient(104deg, rgba(151,147,218,1) 0%, rgba(183,137,205,1) 100%)',
  img: './images/content/lost.svg',
  img2: './images/content/rocket.svg',
  start: 'true',
};

export const heroFour = {
  reverse: true,
  topLine: {
    text: 'Photography and Development',
  },
  headline: 'New ECommerce systems',
  description:
    'We take your products and make them look better than they already are!',
  buttonLabel: 'View Project',

  linkTo: '/more',
  imgStart: '',
  backgroundColor: 'linear-gradient(225deg,#0abac2,#b2de94)',
  img: './images/content/ecommerce.svg',
  img2: './images/content/rocket.svg',
  start: 'true',
};
