import React, { useEffect, useState } from "react";
// import icon1 from '../../icons/blog1.png';
// import icon2 from '../../icons/blog2.png';
// import icon3 from '../../icons/blog3.png';
// import { CgArrowLongRight } from 'react-icons/cg';
import {
  FeaturedContent,
  ServicesContainer,
  Title,
  Wrapper,
  ImgFeatured,
  FeaturedDetails,
  Content,
  Gomad,
  ThumbContent,
  ImgThumb,
  TitleThumb,
  ImgWrapper,
} from "./Styled.js";
// import { getPost } from '../../services/index.js';
import { getPost } from "../../services/indexNew.js";

const Services = () => {
  const [blogger, setBlogger] = useState([]);
  const [mainPost, setMainPost] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getPost(1, 10, 95);
        if (response.success) {
          const { data } = response.result;
          if (data && data.length > 0) {
            setMainPost(data[0]);
            setBlogger(data.slice(1));
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <ServicesContainer className="mt-4" id="Blog">
        <Gomad className="text-center mb-2 mt-4">Mading GO</Gomad>
        {!loading && (
          <Wrapper className="mt-2">
            <FeaturedContent className="mt-4">
              <ImgFeatured src={mainPost.images_url} /> {/* Update this */}
              <FeaturedDetails>
                <Title>{mainPost.title}</Title> {/* Update this */}
                <Content>
                  <div dangerouslySetInnerHTML={{ __html: mainPost.excerpt }} />
                </Content>
                <a
                  target="_blank"
                  href={`https://ganeshaoperation.com/blog/post/${mainPost.slug}`}
                  rel="noreferrer"
                >
                  <button className="btn btn-danger btn-md">
                    Baca Selengkapnya &gt; &gt; &gt;
                  </button>
                </a>
              </FeaturedDetails>
            </FeaturedContent>
            {blogger?.slice(-3).map((post, i) => (
              <ThumbContent
                key={i}
                href={`https://ganeshaoperation.com/blog/post/${post.slug}`}
                target="_blank"
              >
                <ImgWrapper>
                  <ImgThumb src={post.images_url} /> {/* Update this */}
                </ImgWrapper>
                <TitleThumb>{post.title}</TitleThumb> {/* Update this */}
              </ThumbContent>
            ))}
            <a
              className="ms-auto text-danger fw-bold"
              href="https://ganeshaoperation.com/blog"
            >
              <div
                style={{ cursor: "pointer" }}
                className="ms-auto text-danger fw-bold"
              >
                Lihat Semua Artikel &gt; &gt; &gt;
              </div>
            </a>
          </Wrapper>
        )}
      </ServicesContainer>
    </>
  );
};

export default Services;
