import React from 'react'
import Detail3b from './detail3b';
import { homeObjOne } from './detail3b/Data';

const belajar = () => {
  return (
    <>
      <Detail3b {...homeObjOne}/>
    </>
  )
}

export default belajar