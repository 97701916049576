import styled from 'styled-components';

export const ServicesContainer = styled.div`
  padding: 2rem;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`;

export const Gomad = styled.h1`
  color: #1c2237;
  font-family: 'Archivo', sans-serif;
  font-size: 40px;
  font-weight: 700;
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1440px;
  row-gap: 2em;
  column-gap: 2em;
  margin: 0px auto;
  @media screen and (max-width: 600px) {
    column-gap: 0px;

    & > *:nth-child(2) {
      width: 100%;
    }
  }
`;

export const FeaturedContent = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  column-gap: 2rem;
  row-gap: 1rem;
  width: 100%;
  max-width: 1440px;

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const ImgFeatured = styled.img`
  box-shadow: -6px 2px 13px -2px rgba(41, 41, 41, 0.75);
  -webkit-box-shadow: -6px 2px 13px -2px rgba(41, 41, 41, 0.75);
  -moz-box-shadow: -6px 2px 13px -2px rgba(41, 41, 41, 0.75);
  border-radius: 12px;
  width: 32rem;
  align-self: flex-start;
  @media screen and (max-width: 1100px) {
    align-self: center;
    width: 100%;
  }
`;

export const FeaturedDetails = styled.div``;
export const Title = styled.h2``;

export const Content = styled.p`
  /* font-size: 1.2em; */
  margin-top: em;
  @media screen and (max-width: 1100px) {
    font-size: 14px;
  }
`;

export const ThumbContent = styled.a`
  text-decoration: none;
  color: #242424;
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(92, 86, 86, 0.75);
  box-shadow: -4px 5px 13px -4px rgba(92, 86, 86, 0.75);
  -webkit-box-shadow: -4px 5px 13px -4px rgba(92, 86, 86, 0.75);
  -moz-box-shadow: -4px 5px 13px -4px rgba(92, 86, 86, 0.75);

  @media screen and (max-width: 600px) {
    width: 48%;
  }

  &:hover {
    cursor: pointer;

    transition: ease-in-out;
    transition-duration: 200ms;
    color: #242424;
  }
`;

export const ImgWrapper = styled.div``;

export const ImgThumb = styled.img`
  width: 100%;
`;

export const TitleThumb = styled.h5`
  /* text-align: center; */
  padding: 4px 12px;
  @media screen and (max-width: 1100px) {
    font-size: medium;
    font-weight: 600;
  }
  @media screen and (max-width: 600px) {
    font-size: small;
    font-weight: 500;
  }
`;
