import React, { useEffect } from "react";
import { Container } from "../../globalStyles";
import {
  ContentSec,
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  ImgWrapper2,
  ContentColumn,
} from "./ContentStyles";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import Tilt from "react-tilt";
import "./index.css";
import tatapMuka from "./250823-Website-4.png";
import bimbelOnline from "./bimbelonline.png";
const Content = ({
  topLine,
  headline,
  description,
  img,
  img2,
  alt,
  backgroundColor,
  backgroundColor2,
  inverse,
  reverse,
}) => {
  const initial = { opacity: 0, y: 30 };
  const transition = { delay: 0.1, duration: 0.3 };
  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <ContentSec ref={ref}>
      <TextWrapper>
        <TopLine
          initial={initial}
          transition={{ delay: 0.3, duration: 0.6 }}
          animate={animation}
        >
          {topLine.text}
        </TopLine>
        <Heading
          initial={initial}
          transition={transition}
          animate={animation}
          inverse={inverse}
        >
          {headline}
        </Heading>
        <Subtitle
          initial={initial}
          transition={{ delay: 0.7, duration: 0.6 }}
          animate={animation}
          inverse={inverse}
        >
          {description}
        </Subtitle>
      </TextWrapper>
      <Container>
        <ContentRow reverse={reverse}>
          <ContentColumn
            initial={initial}
            transition={transition}
            animate={animation}
          >
            <Tilt
              className="Tilt"
              options={{ max: 20, scale: 1.01, speed: 200 }}
            >
              <ImgWrapper>
                {/* <Img
									src={img2}
									alt={alt}
									whileHover={{ scale: 1.1 }}
									transition={{ duration: 0.5 }}
								/> */}
                <div className="tatapmukawebgo position-relative ">
                  <div className="px-2">
                    <h2 className="fw-bold mt-2">Bimbel Tatap Muka</h2>
                    <p className="descriptionprogramgo">
                      Kamu tipe yang lebih paham saat belajar langsung dengan
                      pengajarnya? Bimbel tatap mukanya Ganesha Operation pas
                      banget buat kamu!
                    </p>
                    <ul className="col-12 col-sm-6 col-lg-10 ulprogramgo">
                      <li>
                        Kamu bisa diajar langsung oleh pengajar-pengajar GO yang
                        akrab dan berkualitas.
                      </li>
                      <li>
                        Bisa berdiskusi dengan pengajar di luar KBM pada saat
                        <i> Tutorial Service Time</i> (TST) agar pemahamanmu
                        akan materi pelajaran meningkat.
                      </li>
                      <li>
                        Kamu juga bisa konsultasi Evaluasi Prestasi Belajar
                        (EPB) dengan konselor GO yang andal. Engga hanya EPB,
                        untuk kamu juga bisa konsultasi PPDB, pemilihan prodi di
                        SNBP, atau UTBK-SNBT.
                      </li>
                      <li>
                        Bonus untuk kamu yang ambil program bimbel tatap muka,
                        kamu juga dapetin aplikasi GO Expert lho, untuk
                        melengkapi proses kamu saat BELAJAR, BERLATIH, dan
                        BERTANDING di mana aja dan kapan aja.
                      </li>
                    </ul>
                  </div>

                  <div className="d-flex position-absolute bottom-0 end-0  ">
                    <img
                      alt="tatapmuka"
                      src={tatapMuka}
                      className="ms-auto imgprogramgo"
                      style={{
                        borderRadius: "1rem",
                      }}
                    />
                  </div>
                </div>
              </ImgWrapper>
            </Tilt>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={transition}
            animate={animation}
          >
            <Tilt
              className="Tilt"
              options={{ max: 20, scale: 1.01, speed: 200 }}
            >
              <ImgWrapper2 backgroundColor={backgroundColor}>
                <div className="tatapmukawebgo position-relative ">
                  <div className="px-2">
                    {" "}
                    <h2 className="fw-bold mt-2">Bimbel Online</h2>
                    <p className="descriptionprogramgo">
                      Untuk kamu yang ingin capai target akademik tapi mager
                      pergi ke tempat bimbel? Kamu wajib coba nih bimbel GO
                      Expert!
                    </p>
                    <ul className="col-12 col-sm-6 col-lg-10 ulprogramgo">
                      <li>
                        Menu yang tersedia super lengkap banget, kamu bisa
                        BELAJAR mulai dari video pembelajaran,
                        <i>e-book</i> teori dan rumus, BERLATIH di menu soal
                        dengan puluhan ribu soal yang relevan dengan materi
                        ujianmu di sekolah atau UTBK-SNBT, dan BERTANDING di
                        menu TOBK.
                      </li>
                      <li>
                        Dan tentunya bisa kamu akses kapan saja dan di mana
                        saja.
                      </li>
                      <li>Biaya lebih terjangkau</li>
                    </ul>
                  </div>

                  <div className="d-flex position-absolute bottom-0 end-0 ">
                    <img
                      alt="bimbol"
                      src={bimbelOnline}
                      className="ms-auto imgprogramgo"
                      style={{
                        borderRadius: "1rem",
                      }}
                    />
                  </div>
                </div>
              </ImgWrapper2>
            </Tilt>
          </ContentColumn>
        </ContentRow>
      </Container>
    </ContentSec>
  );
};

export default Content;
