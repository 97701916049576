import React from 'react';
import './Faq.css';
import Accordion from './Accordion/Accordion';

const Faq = () => {
  return (
    <div className="hero" id="faq">
      <h1 className="judul text-center">Tanya Jawab</h1>
      <div className="container">
        {/* Left Side */}
        <div className="d-none d-lg-block left">
          <div className="img-container">
            <img src="images/faq2.png" alt="faq" />
          </div>
        </div>

        {/* Right Side */}
        <div className="right">
          <Accordion />
        </div>
      </div>
    </div>
  );
};

export default Faq;
