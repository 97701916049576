import React from "react";
import icon1 from "../../icons/belajar.png";
import icon2 from "../../icons/berlatih.png";
import icon3 from "../../icons/bertanding.png";
import Featured from "./featured/Featured";
import { Link } from "react-router-dom";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesH2,
  ServicesIcons,
  TigaButton,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <>
      <ServicesContainer>
        <Featured />
        <ServicesH1 id="services">
          Formula 3B Bantu Kamu Mencapai Target Akademikmu!
        </ServicesH1>
        <img
          src="https://res.cloudinary.com/dzxxupjbz/image/upload/v1722666353/LOGO_formula_3B_FX_gjvuwg.png"
          alt="3b go"
          style={{
            maxWidth: "160px",
          }}
          className="mb-4"
        />
        <ServicesWrapper>
          <Link
            to="/belajar"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ServicesCard>
              <ServicesIcons src={icon1} />
              <ServicesH2>Belajar</ServicesH2>
              <ServicesP>
                GO bantu kamu untuk memahami teori dan konsepnya setiap belajar
                di kelas. Kalau masih bingung, ada <i>Tutorial Service Time</i>{" "}
                (TST) untuk belajar tambahan diluar KBM.
              </ServicesP>
            </ServicesCard>
          </Link>
          <Link
            to="/berlatih"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ServicesCard>
              <ServicesIcons src={icon2} />
              <ServicesH2>Berlatih</ServicesH2>
              <ServicesP>
                Sel-sel otakmu perlu dilatih untuk mengerjakan soal-soal dengan
                tingkat kesulitan yang setara dengan ujianmu. Untuk itu, ada
                Buku Sakti dan Empati yang siap membantu.
              </ServicesP>
            </ServicesCard>
          </Link>
          <Link
            to="/bertanding"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ServicesCard>
              <ServicesIcons src={icon3} />
              <ServicesH2>Bertanding</ServicesH2>
              <ServicesP>
                Setelah berlatih, saatnya menguji sudah seberapa jauh progres
                belajarmu dengan TOBK (<i>Try Out</i> Berbasis Komputer),{" "}
                <i>Racing</i> Soal, dan <i>Battle</i>.
              </ServicesP>
            </ServicesCard>
          </Link>
        </ServicesWrapper>
        <Link
          to="/penjelasan"
          style={{ textDecoration: "none", color: "black" }}
        >
          <TigaButton>Lihat Detail</TigaButton>
        </Link>
      </ServicesContainer>
    </>
  );
};

export default Services;
