export const Data = [
  {
    question:
      'Bagaimana GO dapat meningkatkan prestasi para siswanya?',
    answer:
      'GO selalu berusaha meningkatkan prestasi siswa-siswanya dengan menjalankan pembelajaran yang nyaman dan menyenangkan. Siswa dapat belajar dengan fasilitas-fasilitas yang memastikan pengajaran betul-betul terasa nyaman. Semenjak didirikan, GO juga telah menggunakan cara-cara kreatif yang menyenangkan bagi para siswa dalam memahami pelajaran dan mengerjakan soal.',
  },
  {
    question: 'Apa perbedaan GO dengan bimbingan belajar lainnya?',
    answer:
      'GO merupakan satu-satunya bimbingan belajar yang berhasil meluluskan siswanya sebanyak 50.000 lebih lulus ke PTN & Kedinasan, 2.500 lebih diantaranya diterima di kedokteran.',
  },
  {
    question: 'Apa saja diskon-diskon yang dapat saya terima?',
    answer:
      'Ada diskon untuk anak guru, ada juga diskon untuk yang langsung membayar lunas, untuk kamu yang pernah menjadi siswa GO, untuk yang langsung bareng-bareng daftarnya atau kamu-kamu yang berprestasi di sekolahnya.',
  },
];
