export const navbarData = [
  {
    to: "about",
    text: "About",
  },
  {
    to: "diskon",
    text: "Diskon",
  },
  // {
  //   to: 'services',
  //   text: 'Formula Belajar',
  // },
  {
    to: "testimoni",
    text: "Testimoni",
  },
  {
    to: "faq",
    text: "FAQ",
  },

  {
    to: "Blog",
    text: "Mading GO",
  },
];
