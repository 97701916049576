import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdAddShoppingCart } from "react-icons/md";
import {
  HeroSection,
  Heading,
  HeroText,
  ButtonContainer,
  HeroButton,
  ImageCharacter,
  HeroImage,
  HeroContent,
  ButtonWrapper,
  CharacterContainer,
  HeroButton2,
} from "./HeroStyles";
import { useInView } from "react-intersection-observer";
import Modal2 from "../Modal/Modal2";

const Hero = () => {
  // const Countdown = Countdowntimer();
  // const Show = Countdown.condition;
  // const Month = Countdown.month;
  // console.log(Month, 'ini bulan marque');
  const [showModal2, setShowModal2] = useState(false);
  const dragConstraints = { top: 0, bottom: 0, right: 0, left: 0 };

  const linkWa = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=628112468988&text=&source=&data=",
      "_blank"
    );
  };

  const toggleModal2 = () => {
    if (!showModal2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    setShowModal2(!showModal2);
  };

  const variants = {
    hover: {
      y: 15,
      transition: {
        yoyo: Infinity,
        duration: 0.6,
      },
    },
  };
  const { ref, inView } = useInView({
    rootMargin: "-100px",
  });

  useEffect(() => {}, [inView]);

  return (
    <>
      <HeroSection id="hero">
        <HeroImage className="pattern" src="./images/union.png" />
        <HeroImage className="guy" src="./images/background6.png" />
        <CharacterContainer>
          {/* <ImageCharacter
            dragConstraints={dragConstraints}
            className="one"
            src="./images/background1.png"
          /> */}
          {/* <ImageCharacter
            variants={variants}
            whileHover="hover"
            drag
            dragConstraints={dragConstraints}
            className="three"
            src="./images/background3.png"
          /> */}
        </CharacterContainer>
        <HeroContent>
          <div
            className="mb-5"
            style={{
              marginTop: "15vh",
              fontWeight: "bold",
            }}
          >
            {/* <marquee>
              Dapatkan diskon Rp250.000 selama bulan Januari untuk
              Program Super Intensif dengan melakukan pendaftaran via
              website
            </marquee> */}
          </div>
          <Heading>Ganesha Operation</Heading>
          <HeroText>
            Bimbingan Belajar Terbaik dan Terbesar Se-Indonesia
          </HeroText>
          <ButtonContainer ref={ref}>
            <ButtonWrapper>
              <HeroButton onClick={linkWa} className={inView ? "" : "corner"}>
                {inView ? (
                  <> Hubungi Kami</>
                ) : (
                  <FaWhatsapp color="white" size="2.3rem" />
                )}
              </HeroButton>
              <HeroButton2
                onClick={toggleModal2}
                className={inView ? "" : "corner2"}
              >
                {inView ? (
                  <> Daftar Sekarang</>
                ) : (
                  <MdAddShoppingCart color="white" size="2.3rem" />
                )}
              </HeroButton2>
            </ButtonWrapper>
          </ButtonContainer>
        </HeroContent>
      </HeroSection>
      <Modal2 showModal={showModal2} toggleModal={toggleModal2} />
    </>
  );
};

export default Hero;
