import React from "react";
import {
  FormColumn,
  FormSection,
  FormRow,
  FormTitle,
  FormButton2,
  FormButton3,
} from "./FormStyles";
import { Container } from "../../globalStyles";

const Form2 = () => {
  const d = new Date();
  
  console.log(d);

  

  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn>
            <FormTitle>Silakan Pilih Program</FormTitle>
            {/* <FormButton2
                            onClick={() =>
                                window.open(
                                    "https://ganeshaoperation.com/bimbel/produk/smt2",
                                    "_blank"
                                )
                            }
                        >
                            Program Semester Genap Tahun Ajaran 22/23
                        </FormButton2> */}
            {/* <FormButton2
              onClick={() =>
                window.open(
                  "https://ganeshaoperation.com/bimbel/produk/superintensif",
                  "_blank"
                )
              }
            >
              PROGRAM SUPER INTENSIF TAHUN AJARAN 2023/2024
              <br />
              <small>periode Super Intensif 18 Maret 2024-SNBT 2024</small>
            </FormButton2> */}
            {/* {text >= getDay && (
              <FormButton2
                onClick={() =>
                  window.open(
                    "https://registrasi.ganesahaoperation.com",
                    "_blank"
                  )
                }
              >
                <div style={{ margin: "1rem" }}>TOBK Ganesha Operation</div>
              </FormButton2>
            )} */}
            <FormButton2
              onClick={() =>
                window.open(
                  "https://ganeshaoperation.com/bimbel/produk",
                  "_blank"
                )
              }
            >
              Program 1 Tahun Ajaran 2024/2025
            </FormButton2>
            <FormButton2
              onClick={() =>
                window.open(
                  "https://ganeshaoperation.com/bimbel/produk/bimbelonline",
                  "_blank"
                )
              }
            >
              Program GO Expert TAHUN AJARAN 2024/2025
            </FormButton2>
            {/* <FormButton2
              onClick={() =>
                window.open(
                  "https://ganeshaoperation.com/bimbel/produk/ujianmandiri",
                  "_blank"
                )
              }
            >
              Program Ujian Mandiri Tahun Ajaran 2023/2024
            </FormButton2> */}
            {/* <FormButton2
              onClick={() =>
                window.open("https://ganeshaoperation.com/supracamp", "_blank")
              }
            >
              Program SupraCamp
            </FormButton2> */}
            {/* <FormButton2
              onClick={() =>
                window.open(
                  "https://ganeshaoperation.com/bimbel/produk/semester2",
                  "_blank"
                )
              }
            >
              Program SEMESTER GENAP 2023/2024
            </FormButton2> */}
            {/* <FormButton2
                            onClick={() =>
                                window.open(
                                    "https://ganeshaoperation.com/GObimbelonline",
                                    "_blank"
                                )
                            }
                        >
                            Program Bimbel Online GO Kreasi Tahun
                            Ajaran 22/23
                        </FormButton2> */}
            {/* <FormButton3
                            onClick={() =>
                                window.open(
                                    "https://ganeshaoperation.com/bimbel/produk/SI",
                                    "_blank"
                                )
                            }
                        >
                            <div style={{ margin: "1rem" }}>
                                Super Intensif: Untuk persiapan SNBT
                                Start Belajar: Setelah USP 2023 (3
                                April 2023 s.d. H-1 SNBT 2023)
                                Frekuensi Belajar: 6 PTM/minggu @ 90
                                menit
                            </div>
                        </FormButton3> */}
            {/* <FormButton3
                            onClick={() =>
                                window.open(
                                    "https://ganeshaoperation.com/supracamp",
                                    "_blank"
                                )
                            }
                        >
                            <div style={{ margin: "1rem" }}>
                                SupraCamp: Belajar Intensif Untuk
                                Persiapan SNBT di eL Hotel Royale
                                Bandung Start Belajar Di Hotel 26
                                April s.d. 3 Juni 2023
                            </div>
                        </FormButton3> */}
            {/* <FormButton2
              onClick={() =>
                window.open(
                  "https://ganeshaoperation.com/bimbel/produk/Kedinasan",
                  "_blank"
                )
              }
            >
              <div style={{ margin: "1rem" }}>
                Program Kedinasan Tahun Ajaran 2023/2024
              </div>
            </FormButton2> */}

            {/* <FormButton2
                            onClick={() =>
                                window.open(
                                    "https://ganeshaoperation.com/tobk_nasional_go",
                                    "_blank"
                                )
                            }
                        >
                            Pendaftaran TOBK Nasional GO Tahun Ajaran
                            23/24
                        </FormButton2> */}

            {/* <FormButton2
              onClick={() =>
                window.open(
                  "https://ganeshaoperation.com/bimbel/produk/trisemester",
                  "_blank"
                )
              }
            >
              PROGRAM TRISEMESTER TAHUN AJARAN 2023/2024
              <br />
            </FormButton2> */}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default Form2;
