import React, { useEffect } from "react";
import GlobalStyle from "./globalStyles";
import HomePage from "./pages/HomePage";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Rute from "./components/Rute/Rute";
import Navbar from "./components/Navbar/Navbar";
import AlertDiskonTop from "./components/Navbar/AlertDiskonTop";
import Facebook from "./Facebook";
function App() {
  const [Alert, setAlert] = React.useState(false);

  useEffect(() => {
    ReactGA.initialize("G-GFBCTCXQD2");
    ReactGA.send("pageview");
  }, []);

  useEffect(() => {
    const endDateAlert = new Date("2024-10-13").getTime();
    const now = new Date().getTime();
    const periodAlert = now <= endDateAlert;

    setAlert(periodAlert);

  }, []);

  return (
    <Router>
      <GlobalStyle />
      {Alert && <AlertDiskonTop setAlert={setAlert} />}

      <Navbar alert={Alert} />
      <Facebook />
      <Switch>
        <Route path="/" exact component={HomePage} />
      </Switch>
      <Rute />
      <Footer />
    </Router>
  );
}

export default App;
